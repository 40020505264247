





































import { Component, Mixins, Watch, Vue, Ref } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ToggleButton from '@/components/molecules/ToggleButton.vue'
import CallAttention from '@/components/organisms/CallAttention.vue'
import StudentInformationTeaching from '@/components/organisms/v3/StudentInformationTeaching.vue'
import ModalPointHistory from '@/components/organisms/ModalPointHistory.vue'
import ModalTeachingMemo from '@/components/organisms/ModalTeachingMemo.vue'
import LessonTeacher from '@/mixins/action-cable/LessonTeacher'

export type ButtonName = '授業を終了する' | '授業を退出する'

@Component({
  components: {
    TitleBase,
    ButtonBase,
    ToggleButton,
    CallAttention,
    StudentInformationTeaching,
    ModalPointHistory,
    ModalTeachingMemo,
  },
})
export default class DashboardTeaching extends Mixins(LessonTeacher) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private lessonId = this.$route.params.id
  private isLessonStartUser = true
  private lessonClassName = ''
  private filterPointSortParam = false
  private filterCallSupporterParam = false
  private buttonName(): ButtonName {
    return this.isLessonStartUser ? '授業を終了する' : '授業を退出する'
  }
  // Web APIのレスポンスパラメータのタイプ
  private studentDatas: {
    userId: number
    studentCode: string
    nickname: string
    icon: string
    schoolName: string
    gradeName: string
    status: string
    classModeName: string
    material: string
    pointdata: any
    supporterCall: boolean
  }[] = []

  /**
   * 現在ポイント履歴を表示している生徒ID
   * WebSocketにてデータ通信を受けた場合に表示している生徒のポイント履歴を更新するのに利用する
   */
  private pointHistoryUser: null | { userId: number; nickname: string } = null

  private get filteredStudentDatas() {
    let result = this.studentDatas

    if (this.filterCallSupporterParam) {
      result = result.filter((item: any) => item.supporterCall == true)
    }

    return result
  }

  /** 声掛けアラート用のデータ */
  private resultAttentions: {
    id: number
    userId: number
    lessonUserId: number
    point: number
    totalPoint: number
    reason: string
    alertText: string
    timestamp: string
  }[] = []

  /** ポイント履歴用のデータ */
  private pointHistories: {
    id: number
    userId: number
    point: number
    totalPoint: number
    reason: string
    timestamp: string
  }[] = []

  private pointHistoryData = {
    name: '',
    pointdatas: [
      {
        totalPoint: 0,
        transition: 0,
        description: '',
        timestamp: '',
      },
    ],
  }

  private supporterMemoData: { id: number | null; lessonUserId: number | null; memo: string } = {
    id: null,
    lessonUserId: null,
    memo: '',
  }

  @Ref() modalPointHistory!: ModalPointHistory
  @Ref() modalTeachingMemo!: ModalTeachingMemo

  private showPointHistory(userId: number, nickname: string): void {
    this.pointHistoryUser = { userId: userId, nickname: nickname }
    this.pointHistoryDataUpdate({ userId: userId, nickname: nickname })
    this.modalPointHistory.show()
  }

  /**
   * 該当生徒のポイント履歴のデータを更新する
   */
  private pointHistoryDataUpdate(payload: { userId: number; nickname: string }) {
    // WebSocket通信によりデータが追加されるため対象の生徒のポイント履歴を再度取得する
    const pointHistories = this.pointHistories
      .filter((item: any) => item.userId === payload.userId && item.point > 0)
      .map((item: any) => {
        return {
          totalPoint: item.totalPoint,
          transition: item.point,
          description: this.jaReason(item.reason),
          timestamp: item.timestamp,
        }
      })
      .reverse()

    // モーダル表示しているポイント履歴を更新する
    this.pointHistoryData = {
      name: payload.nickname,
      pointdatas: pointHistories,
    }
  }

  private showTeachingMemo(lessonUserId: number): void {
    this.loadSupprterMemoData(lessonUserId)
    this.modalTeachingMemo.show()
  }

  private async loadSupprterMemoData(lessonUserId: number): Promise<void> {
    const { data: memoData } = await Vue.prototype.$http.httpWithToken.get('/supporter_memos', {
      params: { lessonUserId: lessonUserId },
    })

    if (memoData.id) {
      this.supporterMemoData.id = memoData.id
      this.supporterMemoData.lessonUserId = lessonUserId
      this.supporterMemoData.memo = memoData.memo
    } else {
      this.supporterMemoData.id = null
      this.supporterMemoData.lessonUserId = lessonUserId
      this.supporterMemoData.memo = ''
    }
  }

  private async toggleSupporterCall(userId: number): Promise<any> {
    Vue.prototype.$logger.info('-- toggleSupporterCall')
    Vue.prototype.$logger.info(userId)

    try {
      await Vue.prototype.$http.httpWithToken.patch(`/history/resultAttention/${this.lessonId}/setCalledAt/${userId}`)
      // TODO 声掛け済みとする場合にデータベースを更新する
      const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === userId)
      this.studentDatas[index].supporterCall = false
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  /**
   * 授業を開始したユーザかどうかを確認する
   */
  private loadIsLessonStartUser(): void {
    const params = [`branchId=${this.branchId}`, `lessonId=${this.lessonId}`]
    Vue.prototype.$http.httpWithToken.get(`/lessons/checkStartUser?${params.join('&')}`).then((res: any) => {
      this.isLessonStartUser = res.data.isLessonStartUser
    })
  }

  /**
   * 授業終了（念の為、授業を開始したユーザか否かAPI側で再度確かめる）
   */
  private completeLesson(): void {
    Vue.prototype.$loading.start()
    const params = [`branchId=${this.branchId}`, `lessonId=${this.lessonId}`]
    Vue.prototype.$http.httpWithToken.post(`/lessons/complete?${params.join('&')}`).then(() => {
      window.location.href = '/teacher/dashboard'
    })
  }

  private async mounted() {
    await this.loadIsLessonStartUser()
    // TODO WebSocketのコネクションの確立によるデータ取得とhttp通信によるデータ取得で不整合が生じる可能性があるため
    // TODO 処理の順序を整理する必要あり
    // TODO 恐らく下記の手順にする
    // TODO WebSocket通信確立 -> http通信データ取得 -> WebSocket通信データ更新開始

    // ユーザ一覧の取得
    await this.attentionUsers()
    // 声かけアラート一覧取得
    await this.getResultAttentions()
    // ポイント履歴一覧取得
    await this.getPointHistories()
    // 声掛けアラートより声掛け希望中の状態にする
    this.initSupporterCall()

    // 授業名取得
    this.getLessonClassName()
  }

  private async attentionUsers(): Promise<void> {
    Vue.prototype.$logger.info('-- attentionUsers')
    try {
      const result = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/attentionStudents`)
      Vue.prototype.$logger.info(result)
      this.studentDatas = result.data
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  private async getLessonClassName(): Promise<void> {
    Vue.prototype.$logger.info('-- getLessonClassName')
    try {
      const result = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/className`)
      Vue.prototype.$logger.info(result)
      this.lessonClassName = result.data
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  private async getResultAttentions(): Promise<void> {
    Vue.prototype.$logger.info('-- getResultAttentions')
    try {
      const result = await Vue.prototype.$http.httpWithToken.get(`/history/resultAttention/${this.lessonId}`)
      Vue.prototype.$logger.info(result)
      this.resultAttentions = result.data
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  private async getPointHistories(): Promise<void> {
    Vue.prototype.$logger.info('-- getPointHistories')
    try {
      const result = await Vue.prototype.$http.httpWithToken.get(`/history/pointHistory/${this.lessonId}`)
      Vue.prototype.$logger.info(result)
      this.pointHistories = result.data
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました')
      throw 'Drill Error!'
    }
  }

  /**
   * 初期表示時に声掛けアラートが上がっている生徒を声掛け希望中の状態にする
   */
  private initSupporterCall(): void {
    // 声掛けアラートの声掛け希望中のユーザーIDを取得
    const userIds = this.resultAttentions
      .filter((item: any) => item.calledAt === null)
      .map((item: any) => item.userId)
      .filter((x, i, self) => self.indexOf(x) === i)

    userIds.forEach((userId: any) => {
      const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === userId)
      this.studentDatas[index].supporterCall = true
    })
  }

  @Watch('messages', { deep: true })
  public messagesChange() {
    Vue.prototype.$logger.info('-- messagesChange')
    Vue.prototype.$logger.info(this.messages)

    const lastMessage = this.messages.slice(-1)[0]

    // 授業終了の場合は何もしない
    if (lastMessage.messageType === 'stopStreams') return

    switch (lastMessage.messageType) {
      case 'supporterCallOff':
        this.supporterCallOff(lastMessage)
        break
      case 'currentPage':
        this.currentPage(lastMessage)
        break
      case 'classModeName':
        this.classModeName(lastMessage)
        break
    }

    if (
      lastMessage.messageType !== 'supporterCallOff' &&
      lastMessage.messageType !== 'currentPage' &&
      lastMessage.messageType !== 'classModeName'
    ) {
      // 声掛け希望中にする
      this.supporterCallOn(lastMessage)
      // 声かけアラートに追加する
      this.resultAttentions.push(lastMessage.data)
      // ポイント履歴に追加する
      this.pointHistories.push(lastMessage.data)
    }

    // ポイントを加算する
    this.addPoint(lastMessage)

    // ポイント履歴表示時はポイント履歴にも加算する
    if (this.pointHistoryUser) {
      this.pointHistoryDataUpdate({ userId: this.pointHistoryUser.userId, nickname: this.pointHistoryUser.nickname })
    }
  }

  /**
   * サポーターを呼ぶを活性化する
   */
  private supporterCallOn(lastMessage: any): void {
    // 声掛けアラートに上がったら対象生徒をサポーターを呼ぶ状態にする
    const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === lastMessage.data.userId)

    // 振り替えのアラートにより対象生徒がいない場合は何もしない
    if (index < 0) return

    this.studentDatas[index].supporterCall = true
  }

  /**
   * サポーターを呼ぶを声掛け済みにする
   */
  private supporterCallOff(lastMessage: any): void {
    // サポーターを呼ぶを押された場合は対象生徒をサポーターを呼ぶ状態にする
    const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === lastMessage.data.userId)
    // 声掛けアラートの該当生徒の項目を削除する
    this.resultAttentions = this.resultAttentions.filter((item: any) => item.userId !== lastMessage.data.userId)
    this.studentDatas[index].supporterCall = false

    // 声掛け完了とし、アラートメッセージをリセットする
    this.studentDatas[index].pointdata.transition = 0
    this.studentDatas[index].pointdata.timestamp = ''
    this.studentDatas[index].pointdata.description = ''
  }

  /**
   * ポイントを加算する
   */
  private addPoint(lastMessage: any): void {
    // ポイントが1以上でない場合は何もしない
    if (!Number.isInteger(lastMessage.data.point)) return
    if (lastMessage.data.point <= 0) return

    // 加算
    const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === lastMessage.data.userId)
    this.studentDatas[index].pointdata.totalPoint = lastMessage.data.totalPoint
    this.studentDatas[index].pointdata.timestamp = lastMessage.data.timestamp
    this.studentDatas[index].pointdata.transition = lastMessage.data.point
    this.studentDatas[index].pointdata.description = this.jaReason(lastMessage.data.reason)
  }

  /**
   * 現在ページを更新する
   */
  private currentPage(lastMessage: any): void {
    Vue.prototype.$logger.info('-- DashboardTeaching currentPage')
    Vue.prototype.$logger.info(lastMessage)

    const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === lastMessage.data.userId)
    this.studentDatas[index].status = lastMessage.data.currentPage
    this.studentDatas[index].material = lastMessage.data.pageTitle
  }

  /**
   * 現在の授業モード名を更新する
   */
  private classModeName(lastMessage: any): void {
    Vue.prototype.$logger.info('-- DashboardTeaching classModeName')
    Vue.prototype.$logger.info(lastMessage)

    const index = this.studentDatas.findIndex((studentData: any) => studentData.userId === lastMessage.data.userId)
    // 現在の授業モード名を更新
    this.studentDatas[index].classModeName = lastMessage.data.classModeName
    // 授業モードを切り替えるため現在ページ情報もリセットする
    this.studentDatas[index].status = lastMessage.data.currentPage
    // 授業モードを切り替えるため現在ページタイトルもリセットする
    this.studentDatas[index].material = lastMessage.data.pageTitle
  }

  private jaReason(reason: string): string {
    let result = ''

    switch (reason) {
      case 'correctAnswerRate50':
        result = '直近ドリルの正答率5割未満'
        break
      case 'correctAnswerRate30':
        result = '直近ドリルの正答率3割未満'
        break
      case 'timePassed10':
        result = '10分停止'
        break
      case 'timePassed20':
        result = '20分停止'
        break
      case 'homeworkNotYet':
        result = '宿題未提出'
        break
    }

    return result
  }

  /**
   * 生徒一覧のソート
   */
  private sortStudentDatas() {
    this.studentDatas.sort((studentData1: any, studentData2: any) => {
      return studentData2.pointdata.totalPoint - studentData1.pointdata.totalPoint
    })
  }

  /**
   * 声掛け希望者のみかまたは全員を表示する
   */
  private filterCallSupporter(): void {
    this.filterCallSupporterParam = !this.filterCallSupporterParam
  }
}
