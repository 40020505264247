















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ImageRound from '@/components/atoms/ImageRound.vue'
import StudentFaceIcon from '@/components/atoms/StudentFaceIcon.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import LabelPointTransition from '@/components/molecules/LabelPointTransition.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { TweenLite } from 'gsap'

@Component({
  components: {
    ColoredBox,
    ImageRound,
    StudentFaceIcon,
    LabelBase,
    LabelPointTransition,
    ButtonBase,
  },
})
export default class StudentInformationTeaching extends Vue {
  @Prop()
  student!: { [key: string]: any }

  @Prop()
  onClickPointHistory!: any

  @Prop()
  onClickTeachingMemo!: any

  @Prop()
  onClickSupporterCall!: any

  private tweenedTotalPoint: number = this.student.pointdata.totalPoint

  private get animatedTotalPoint() {
    return this.tweenedTotalPoint.toFixed(0)
  }

  @Watch('student', { deep: true })
  onStudent(newValue: any, oldValue: any) {
    TweenLite.to(this.$data, { duration: 0.5, tweenedTotalPoint: newValue.pointdata.totalPoint })
  }
}
